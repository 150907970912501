import ContentsTypes from "@shared/enums/contentsTypes";
import ContentsCustomTypes from "@project/enums/contentsCustomTypes.enum";
export const PresentationOfAPageDescriptor = {
    label: "Container de présentation d'un menu",
    type: ContentsCustomTypes.PRESENTATION_CONTENT_PAGE,
    icon: "box",
    editionModal: true,
    editionModalPosition: "top",
    children: [
        {
            label: "Titre",
            key: "title",
            type: ContentsTypes.INPUT,
            value: "",
        },
        {
            label: "Titre du menu précédent",
            key: "previousMenuTitle",
            type: ContentsTypes.INPUT,
            value: "",
        },
        {
            label: "Titre du menu suivant",
            key: "nextMenuTitle",
            type: ContentsTypes.INPUT,
            value: "",
        },
        {
            label: "Lier avec l'item du menu correspondant",
            key: "linkWithMenuItem",
            type: ContentsTypes.INPUT_NUMBER,
            value: "",
        },
        {
            label: "contenu dynamique",
            key: "contentPageDynamic",
            type: ContentsTypes.DYNAMIC,
            value: "",
        },
    ],
};
