import ContentsTypes from "@shared/enums/contentsTypes";
import customTypes from "@project/enums/contentsCustomTypes.enum";
const getDynamicAdminContentsTypes = ({ type }) => {
    switch (type) {
        case ContentsTypes.CONTAINER:
        case ContentsTypes.EXPANSION_PANEL_DETAILS:
        case ContentsTypes.DYNAMIC:
            return [customTypes.CONTENT_PAGE, customTypes.PRESENTATION_CONTENT_PAGE];
        default:
            return [];
    }
};
export default getDynamicAdminContentsTypes;
