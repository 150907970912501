// import React from "react";
// import customTypes from "customComponents/customTypes";
const getEditorComponent = ({ content /* handleContentChange, value, id */, }) => {
    const { type } = content;
    switch (type) {
        default:
            return null;
    }
};
export default getEditorComponent;
