import ContentsTypes from "@shared/enums/contentsTypes";
import ContentsCustomTypes from "@project/enums/contentsCustomTypes.enum";
export const ContentsOfAMenuPageDescriptor = {
    label: "Contenus d'une page de Menu",
    type: ContentsCustomTypes.CONTENT_PAGE,
    icon: "address-card",
    editionModal: true,
    editionModalPosition: "top",
    children: [
        {
            label: "Positionnée à droite",
            key: "position",
            type: ContentsTypes.CHECKBOX,
            value: "",
        },
        {
            label: "Image",
            key: "image",
            type: ContentsTypes.IMAGE,
            value: "",
        },
        {
            label: "titre",
            key: "title",
            type: ContentsTypes.TEXT,
            value: "",
        },
        {
            label: "Description",
            key: "description",
            type: ContentsTypes.TEXT,
            value: "",
        },
    ],
};
