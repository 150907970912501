import CmsHooks from "@shared/utils/register/CmsHooks";
import { HOOKS } from "@shared/utils/register/definitions";
import getEditorComponent from "@project/utils/getEditorComponent";
import getDynamicAdminContentsTypes from "@project/utils/getDynamicAdminContentsTypes";
import getFormComponent from "@project/utils/getFormComponent";
import customContents from "@project/contents/customContents";
import customContentTypes from "@project/enums/contentsCustomTypes.enum";

export default () => {
  CmsHooks.registerHook(
    HOOKS.TEMPLATEADMIN_UTILS_GETEDITORCOMPONENT,
    getEditorComponent,
  );
  CmsHooks.registerHook(
    HOOKS.TEMPLATEADMIN_UTILS_GETDYNAMICADMINCONTENTSTYPES,
    getDynamicAdminContentsTypes,
  );
  CmsHooks.registerHook(HOOKS.CONTENTFORM_GETFORMCOMPONENT, getFormComponent);
  CmsHooks.registerHook(HOOKS.ADMINCONTENTS_UTILS_CONTENTS, customContents);
  CmsHooks.registerHook(HOOKS.CUSTOM_CONTENT_TYPES, customContentTypes);
};
